import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "validate-input-container",
  "pb-3": ""
}
const _hoisted_2 = {
  key: 2,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tag === "input")
      ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
          key: 0,
          class: ["form-control", { 'is-invalid': _ctx.inputRef.error }],
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputRef.val) = $event))
        }, _ctx.$attrs), null, 16)), [
          [_vModelDynamic, _ctx.inputRef.val]
        ])
      : (_ctx.tag === "textarea")
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", _mergeProps({
            key: 1,
            class: ["form-control", { 'is-invalid': _ctx.inputRef.error }],
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputRef.val) = $event))
          }, _ctx.$attrs), "        ", 16)), [
            [_vModelText, _ctx.inputRef.val]
          ])
        : _createCommentVNode("", true),
    (_ctx.inputRef.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.inputRef.message), 1))
      : _createCommentVNode("", true)
  ]))
}