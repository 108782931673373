<template>
  <Teleport to="#modal">
    <div class="modal d-block" tabindex="-1" v-if="visble">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="onClose"
            >
            </button>
          </div>
          <div class="modal-body">
            <slot>
              <p>Modal body text goes here</p>
            </slot>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="onClose"
            >
              {{ closeText}}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="onConfirm"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useDOMCreate from '@/hooks/useDOMCreate'
export default defineComponent({
  name: 'Modal',
  props: {
    title: String,
    closeText: {
      type: String,
      default: 'Close'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    visble: {
      type: Boolean,
      default: false
    }
  },
  emits: ['modal-on-close', 'modal-on-confirm'],
  setup (props, context) {
    useDOMCreate('modal')
    const onClose = () => {
      context.emit('modal-on-close')
    }
    const onConfirm = () => {
      context.emit('modal-on-confirm')
    }
    return {
      onClose,
      onConfirm
    }
  }
})
</script>
