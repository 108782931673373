import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore, Commit } from 'vuex'
import request from '@/utils/request'
import { AxiosRequestConfig } from 'axios'
import { arrToObj, objToArr } from '@/utils/helper'
export interface ImageProps {
  _id?: string;
  url?: string;
  createdAt?: string;
  fitUrl?: string;
}
export interface ColumnProps {
  _id: string;
  title: string;
  avatar?: ImageProps;
  description: string;
  createAt?: string;
}
export interface ResponseType<P = Record<string, never>> {
  code: number;
  msg: string;
  data: P;
}
interface ListProps<P>{
  [id: string]: P
}
export interface UserProps {
  isLogin: boolean;
  _id?: string;
  email?: string;
  nickName?: string;
  column?: string;
  description?: string;
  avatar?: ImageProps;
}
export interface PostProps {
  _id?: string;
  title: string;
  excerpt?: string;
  content?: string;
  image?: ImageProps | string;
  createdAt?: string;
  column: string;
  author?: string | UserProps;
}
interface GlobalColumnsProps {
  data: ListProps<ColumnProps>,
  currentPage: number,
  total: number
}
interface GlobalPostsProps {
  data: ListProps<PostProps>,
  loadedColumns: {[id: string]: { currentPage: number, count: number } }
}
export interface GlobalErrorProps {
  status: boolean;
  message?: string
}
export interface GlobalDataProps {
  error: GlobalErrorProps;
  columns: GlobalColumnsProps;
  posts: GlobalPostsProps;
  user: UserProps;
  loading: boolean;
  token: string
}

const asyncAndComit = async (url: string, mutationName: string, commit: Commit,
  config: AxiosRequestConfig = { method: 'get' }, extraData?: any) => {
  const { data } = await request(url, config)
  if (extraData) {
    commit(mutationName, { data, extraData })
  } else {
    commit(mutationName, data)
  }
  return data
}
export * from '@/testData'

export const key: InjectionKey<Store<GlobalDataProps>> = Symbol('MyStore')

export const store = createStore<GlobalDataProps>({
  state: {
    error: {
      status: false,
      message: ''
    },
    user: {
      _id: '',
      email: '',
      nickName: '',
      isLogin: false,
      column: '',
      description: '',
      avatar: {
        _id: '',
        url: '',
        createdAt: ''
      }
    },
    columns: { data: {}, currentPage: 0, total: 0 },
    posts: { data: {}, loadedColumns: {} },
    loading: false,
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    login (state, rawData) {
      const token = rawData.token
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
      }
    },
    fetchCurrentUser (state, rawData) {
      if (rawData._id) {
        state.user = {
          ...rawData,
          isLogin: true
        }
      }
    },
    logout (state) {
      state.user = {
        _id: '',
        email: '',
        nickName: '',
        isLogin: false,
        column: '',
        description: '',
        avatar: {
          _id: '',
          url: '',
          createdAt: ''
        }
      }
      state.loading = false
      localStorage.removeItem('token')
      state.token = ''
    },
    createArticle (state, newPost: PostProps) {
      state.posts.data[newPost._id as string] = newPost
    },
    fetchColumns (state, rawData) {
      const orginalData = state.columns
      const { list, count, currentPage } = rawData
      state.columns = {
        data: { ...orginalData.data, ...arrToObj(list) },
        total: count,
        currentPage: currentPage * 1
      }
    },
    fetchColumn (state, rawData) {
      state.columns.data[rawData._id] = rawData
    },
    fetchPosts (state, { data: rowData, extraData: columnId }) {
      const orginalData = state.posts.data
      state.posts.data = { ...orginalData, ...arrToObj(rowData.list) }
      state.posts.loadedColumns[columnId] = {
        currentPage: rowData.currentPage,
        count: rowData.count
      }
    },
    setLoading (state, rawData) {
      state.loading = rawData
    },
    setError (state, e: GlobalErrorProps) {
      state.error = e
    },
    fetchPost (state, rawData) {
      state.posts.data[rawData._id] = rawData
    },
    updatePost (state, data) {
      state.posts.data[data._id] = data
    },
    deletePost (state, id) {
      delete state.posts.data[id]
    }
  },
  getters: {
    getColumnById: (state) => (id: string) => {
      return state.columns.data[id]
    },
    getPostsByCid: (state) => (cid: string) => {
      return objToArr(state.posts.data).filter(p => p.column === cid)
    },
    fetchArticleLength: (state) => {
      return state.posts.data.length
    },
    getCurrentPost: (state) => (id: string) => {
      return state.posts.data[id]
    },
    getColums: (state) => {
      return objToArr(state.columns.data)
    },
    getPosts: (state) => {
      return objToArr(state.posts.data)
    },
    getPostTotal: (state) => (cid: string) => {
      return state.posts.loadedColumns[cid] ? state.posts.loadedColumns[cid].count : 0
    },
    getPostCurrentPage: (state) => (cid: string) => {
      return state.posts.loadedColumns[cid] ? state.posts.loadedColumns[cid].currentPage : 0
    }
  },
  actions: {
    login ({ commit }, params) {
      return asyncAndComit('/user/login', 'login', commit, { method: 'post', data: params })
    },
    fetchCurrentUser ({ commit }) {
      return asyncAndComit('/user/current', 'fetchCurrentUser', commit)
    },
    fetchColumns ({ state, commit }, params = {}) {
      const { currentPage = 1, pageSize = 6 } = params
      // if (!state.columns.isLoaded) {
      //   asyncAndComit('/columns', 'fetchColumns', commit)
      // }
      if (state.columns.currentPage < currentPage) {
        asyncAndComit('/columns', 'fetchColumns', commit, {
          method: 'get',
          params: {
            currentPage,
            pageSize
          }
        })
      }
    },
    fetchColumn ({ state, commit }, cid) {
      if (!state.columns.data[cid]) {
        asyncAndComit(`/columns/${cid}`, 'fetchColumn', commit)
      }
    },
    fetchPosts ({ state, commit }, params) {
      // if (!state.posts.loadedColumns.includes(cid)) {
      //   asyncAndComit(`/columns/${cid}/posts`, 'fetchPosts', commit, { method: 'get' }, cid)
      // }
      const { cid, currentPage = 1, pageSize = 5 } = params
      if ((state.posts.loadedColumns[cid]?.currentPage || 0) < currentPage) {
        asyncAndComit(`/columns/${cid}/posts?currentPage=${currentPage}&pageSize=${pageSize}`, 'fetchPosts', commit, { method: 'get' }, cid)
      }
    },
    loginAndFetchUserInfo ({ dispatch }, params) {
      return dispatch('login', params).then(() => {
        return this.dispatch('fetchCurrentUser')
      })
    },
    createPost ({ commit }, payload) {
      return asyncAndComit('/posts', 'createPost', commit, {
        method: 'post',
        data: payload
      })
    },
    fetchPost ({ state, commit }, id) {
      const currentPost = state.posts.data[id]
      if (!currentPost || !currentPost.content) {
        return asyncAndComit(`/posts/${id}`, 'fetchPost', commit)
      } else {
        return Promise.resolve(currentPost)
      }
    },
    updatePost ({ commit }, { id, payload }) {
      return asyncAndComit(`/posts/${id}`, 'updatePost', commit, {
        method: 'patch',
        data: payload
      })
    },
    deletePost ({ commit }, id) {
      return asyncAndComit(`/posts/${id}`, 'deletePost', commit, {
        method: 'delete'
      })
    }
  }
})

export function useStore () {
  return baseUseStore(key)
}
