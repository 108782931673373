import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "m-auto w-75 mt-3 create-post-page" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback mt-1"
}
const _hoisted_6 = { class: "btn btn-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uploader = _resolveComponent("uploader")!
  const _component_validate_input = _resolveComponent("validate-input")!
  const _component_editor = _resolveComponent("editor")!
  const _component_validate_form = _resolveComponent("validate-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("h3", null, "新建文章", -1)),
    _createVNode(_component_uploader, {
      action: "/upload",
      beforeUpload: _ctx.uploadCheck,
      onFileUploaded: _ctx.handleFileUploaded,
      uploaded: _ctx.uploadedData,
      class: "d-flex align-items-center justify-content-center bg-light text-secondary w-100 my-4"
    }, {
      loading: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "d-flex" }, [
          _createElementVNode("div", {
            class: "spinner-border text-secondary",
            role: "status"
          }, [
            _createElementVNode("span", { class: "sr-only" })
          ]),
          _createElementVNode("h2", null, " 正在上传...")
        ], -1)
      ])),
      uploaded: _withCtx((dataProps) => [
        _createElementVNode("img", {
          src: dataProps.uploadedData.url,
          height: "200px"
        }, null, 8, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "点击上传头图", -1))
      ]),
      _: 1
    }, 8, ["beforeUpload", "onFileUploaded", "uploaded"]),
    _createVNode(_component_validate_form, { onFormSubmit: _ctx.onFormSummit }, {
      submit: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.isEditMode ? '更新文章' : '发表文章'), 1)
      ]),
      clear: _withCtx(() => _cache[6] || (_cache[6] = [])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "文章标题", -1)),
          _createVNode(_component_validate_input, {
            rules: _ctx.articleNameRules,
            modelValue: _ctx.articleNameVal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.articleNameVal) = $event)),
            type: "text",
            placeholder: "请输入文章标题"
          }, null, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "文章内容", -1)),
          _createVNode(_component_editor, {
            modelValue: _ctx.contentVal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentVal) = $event)),
            options: _ctx.editOptions,
            ref: "editorRef",
            onChange: _ctx.checkEditor,
            onBlur: _ctx.checkEditor,
            class: _normalizeClass({'is-invalid': !_ctx.editorStatus.isValid})
          }, null, 8, ["modelValue", "options", "onChange", "onBlur", "class"]),
          (!_ctx.editorStatus.isValid)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.editorStatus.message), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onFormSubmit"])
  ]))
}