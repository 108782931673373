import { defineStore } from 'pinia'

export interface GlobalErrorProps {
  status: boolean,
  message?: string
}

export interface GlobalStatusProps {
    error: GlobalErrorProps,
    loading: boolean
}

export const useGlobalStore = defineStore('global', {
  state: (): GlobalStatusProps => ({
    error: {
      status: false
    },
    loading: false
  }),
  actions: {
    setLoading (status: boolean) {
      this.loading = status
    },
    setError (error: GlobalErrorProps) {
      this.error = error
    }
  }
})
