import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-auto w-50 mt-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validate_input = _resolveComponent("validate-input")!
  const _component_validate_form = _resolveComponent("validate-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_validate_form, { onFormSubmit: _ctx.onFormSummit }, {
      submit: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("span", { class: "btn btn-primary" }, "提交", -1)
      ])),
      clear: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("span", { class: "btn btn-secondary" }, "清空", -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            for: "inputPassword5",
            class: "form-label"
          }, "邮箱地址", -1)),
          _createVNode(_component_validate_input, {
            rules: _ctx.emailRules,
            modelValue: _ctx.emailVal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailVal) = $event)),
            type: "text",
            placeholder: "请输入邮箱地址"
          }, null, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("label", {
            for: "inputPassword5",
            class: "form-label"
          }, "密码", -1)),
          _createVNode(_component_validate_input, {
            rules: _ctx.passwordRules,
            modelValue: _ctx.passwordVal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordVal) = $event)),
            type: "password",
            placeholder: "请输入密码"
          }, null, 8, ["rules", "modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["onFormSubmit"])
  ]))
}