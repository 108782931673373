<template>
  <div class='home-page'>
    <section class='py-5 text-center container'>
      <div class='row py-lg-5'>
        <div class='col-lg-6 col-md-8 mx-auto'>
          <img src='../assets/callout.svg' alt='callout' class='w-50' />
          <h2 class='font-weight-light'>随心写作，自由表达</h2>
          <p>
            <router-link :to='`/create/${columnId}`' class='btn btn-primary my-2'>开始写文章</router-link>
          </p>
        </div>
      </div>
    </section>
    <h4 class='font-weight-bold text-center'>发现精彩</h4>
    <column-list :list='list'></column-list>
    <div class="loadmore-btn text-center mt-4">
      <button
        class="btn btn-outline-primary mt-2 mb-2 mx-auto btn-block w-25"
        @click="loadMorePage"
        v-if="!isLastPage"
      >
        加载更多
      </button>
    </div>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted } from 'vue'
import ColumnList from '../components/ColumnList.vue'
import { useStore } from '@/store'
import useLoadMore from '@/hooks/useLoadMore'
export default defineComponent({
  name: 'Home',
  components: {
    ColumnList
  },
  setup () {
    const store = useStore()
    const columnId = computed(() =>
      store.state.user && store.state.user.column
    )
    const currentPage = computed(() => store.state.columns.currentPage)
    onMounted(() => {
      store.dispatch('fetchColumns', { pageSize: 3 })
    })
    const list = computed(() => store.getters.getColums)
    const total = computed(() => store.state.columns.total)
    const { loadMorePage, isLastPage } = useLoadMore('fetchColumns', total, { currentPage: (currentPage.value ? currentPage.value + 1 : 2), pageSize: 3 })
    return {
      list,
      columnId,
      loadMorePage,
      isLastPage,
      currentPage
    }
  }
})
</script>
