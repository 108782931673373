import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vue-easymde-editor" }

import { ref, onMounted, onUnmounted, watch } from 'vue'
import EasyMDE, { Options } from 'easymde'
// 类型属性事件
interface EditorProps {
  modelValue?: string;
  options?: Options;
}
interface EditorEvents {
  (type: 'update:modelValue', value: string): void;
  (type: 'change', value: string): void;
  (type: 'blur'): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Editor',
  props: {
    modelValue: {},
    options: {}
  },
  emits: ["update:modelValue", "change", "blur"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
const emit = __emit

const textAreaRef = ref<null | HTMLTextAreaElement>(null)
let easyMDEInstance: EasyMDE | null = null
const innerValue = ref(props.modelValue || '')
watch(() => props.modelValue, (newValue) => {
  if (easyMDEInstance) {
    if (innerValue.value !== newValue) {
      easyMDEInstance.value(newValue || '')
    }
  }
})
onMounted(() => {
  if (textAreaRef.value) {
    // 组装cofig
    const config: Options = {
      ...(props.options || {}),
      element: textAreaRef.value,
      initialValue: innerValue.value
    }
    easyMDEInstance = new EasyMDE(config)
    // 监听事件
    easyMDEInstance.codemirror.on('change', () => {
      if (easyMDEInstance) {
        // 拿到当前的值
        const updateValue = easyMDEInstance.value()
        innerValue.value = updateValue
        emit('update:modelValue', updateValue)
        emit('change', updateValue)
      }
    })
    easyMDEInstance.codemirror.on('blur', () => {
      if (easyMDEInstance) {
        emit('blur')
      }
    })
  }
})
// 销毁对应实例
onUnmounted(() => {
  if (easyMDEInstance) {
    easyMDEInstance.toTextArea()
    easyMDEInstance = null
  }
})

const clear = () => {
  if (easyMDEInstance) {
    easyMDEInstance.value('')
  }
}
const getMDEInstance = () => {
  return easyMDEInstance
}
__expose({
  clear,
  getMDEInstance
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      ref_key: "textAreaRef",
      ref: textAreaRef
    }, null, 512)
  ]))
}
}

})