<template>
  <nav
    class="navbar bg-primary justify-content-between mb-1 p-2 h-auto"
    data-bs-theme="dark"
  >
    <router-link to="/" class="navbar-brand" href="#">乐子专栏</router-link>
    <ul v-if="!user.isLogin" class="list-inline mb-0">
      <li class="list-inline-item">
        <router-link to="/login" class="btn btn-outline-light my-2">登录</router-link>
      </li>
      <li class="list-inline-item">
        <router-link to="/registry" class="btn btn-outline-light my-2">注册</router-link>
      </li>
    </ul>
    <ul v-else class="list-inline mb-0">
      <li class="list-inline-item">
        <drop-down :title="`你好 ${user.nickName}`">
          <drop-down-item><router-link :to="`/create/${columnId}`">新建文章</router-link></drop-down-item>
          <drop-down-item><router-link :to="`/column/${columnId}`">我的专栏</router-link></drop-down-item>
          <drop-down-item :disabled="true"><a href="#">编辑资料</a></drop-down-item>
          <drop-down-item><router-link to="/" @click="onClickLogout">退出登录</router-link></drop-down-item>
        </drop-down>
    </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import DropDown from './DropDown.vue'
import DropDownItem from './DropDownItem.vue'
import { useStore } from '@/store'
export default defineComponent({
  name: 'GlobalHeader',
  components: {
    DropDown,
    DropDownItem
  },
  setup () {
    const store = useStore()
    const user = computed(() => store.state.user)
    const columnId = computed(() => store.state.user.column)
    const onClickLogout = () => {
      store.commit('logout')
    }
    return {
      columnId,
      onClickLogout,
      user
    }
  }
})
</script>
