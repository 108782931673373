<template>
  <div class="vue-easymde-editor">
    <textarea ref="textAreaRef"></textarea>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, defineExpose, ref, onMounted, onUnmounted, watch } from 'vue'
import EasyMDE, { Options } from 'easymde'
// 类型属性事件
interface EditorProps {
  modelValue?: string;
  options?: Options;
}
interface EditorEvents {
  (type: 'update:modelValue', value: string): void;
  (type: 'change', value: string): void;
  (type: 'blur'): void;
}
const props = defineProps<EditorProps>()
const emit = defineEmits<EditorEvents>()

const textAreaRef = ref<null | HTMLTextAreaElement>(null)
let easyMDEInstance: EasyMDE | null = null
const innerValue = ref(props.modelValue || '')
watch(() => props.modelValue, (newValue) => {
  if (easyMDEInstance) {
    if (innerValue.value !== newValue) {
      easyMDEInstance.value(newValue || '')
    }
  }
})
onMounted(() => {
  if (textAreaRef.value) {
    // 组装cofig
    const config: Options = {
      ...(props.options || {}),
      element: textAreaRef.value,
      initialValue: innerValue.value
    }
    easyMDEInstance = new EasyMDE(config)
    // 监听事件
    easyMDEInstance.codemirror.on('change', () => {
      if (easyMDEInstance) {
        // 拿到当前的值
        const updateValue = easyMDEInstance.value()
        innerValue.value = updateValue
        emit('update:modelValue', updateValue)
        emit('change', updateValue)
      }
    })
    easyMDEInstance.codemirror.on('blur', () => {
      if (easyMDEInstance) {
        emit('blur')
      }
    })
  }
})
// 销毁对应实例
onUnmounted(() => {
  if (easyMDEInstance) {
    easyMDEInstance.toTextArea()
    easyMDEInstance = null
  }
})

const clear = () => {
  if (easyMDEInstance) {
    easyMDEInstance.value('')
  }
}
const getMDEInstance = () => {
  return easyMDEInstance
}
defineExpose({
  clear,
  getMDEInstance
})
</script>
