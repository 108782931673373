<template>
  <div class='m-auto w-75 mt-3 create-post-page'>
    <h3>新建文章</h3>
    <uploader
      action="/upload"
      :beforeUpload="uploadCheck"
      @file-uploaded="handleFileUploaded"
      :uploaded="uploadedData"
      class="d-flex align-items-center justify-content-center bg-light text-secondary w-100 my-4"
    >
      <h2>点击上传头图</h2>
      <template #loading>
        <div class="d-flex">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
          <h2> 正在上传...</h2>
        </div>
      </template>
      <template #uploaded="dataProps">
        <img :src="dataProps.uploadedData.url" height="200px">
      </template>
    </uploader>
    <validate-form @form-submit='onFormSummit'>
      <div class='mb-3'>
        <label class='form-label'>文章标题</label>
        <validate-input :rules='articleNameRules' v-model='articleNameVal'
        type='text' placeholder='请输入文章标题' />
      </div>
      <div class='mb-3'>
        <label class='form-label'>文章内容</label>
        <editor
          v-model='contentVal'
          :options="editOptions"
          ref="editorRef"
          @change="checkEditor"
          @blur="checkEditor"
          :class="{'is-invalid': !editorStatus.isValid}"
        ></editor>
        <span v-if="!editorStatus.isValid" class='invalid-feedback mt-1'>{{ editorStatus.message }}</span>
      </div>
      <template #submit>
        <span class='btn btn-primary'>{{ isEditMode ? '更新文章' : '发表文章' }}</span>
      </template>
      <template #clear>
      </template>
    </validate-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import EasyMDE, { Options } from 'easymde'
import ValidateInput, { RuleProps } from '@/components/ValidateInput.vue'
import ValidateForm from '@/components/ValidateForm.vue'
import Uploader from '@/components/Uploader.vue'
import { useStore, PostProps, ResponseType, ImageProps } from '@/store'
import { beforeUploadCheck } from '@/utils/helper'
import createMessage from '@/components/createMessage'
import Editor from '@/components/Editor.vue'

interface EditorInstance {
  clear: () => void,
  getMDEInstance: () => EasyMDE | null
}
export default defineComponent({
  name: 'CreateArticle',
  components: {
    ValidateForm,
    ValidateInput,
    Uploader,
    Editor
  },
  setup () {
    const uploadedData = ref()
    const store = useStore()
    const editorStatus = reactive({
      isValid: true,
      message: ''
    })
    const router = useRouter()
    const route = useRoute()
    const isEditMode = !!route.query.id
    const articleNameVal = ref('')
    const contentVal = ref('')
    let imageId = ''
    const editorRef = ref<null | EditorInstance>()
    const editOptions: Options = {
      spellChecker: false
    }
    const articleNameRules: RuleProps = [
      { type: 'required', message: '文章标题不能为空' }
    ]
    const contentRules: RuleProps = [
      { type: 'required', message: '文章内容不能为空' }
    ]
    const checkEditor = () => {
      if (contentVal.value.trim() === '') {
        editorStatus.isValid = false
        editorStatus.message = '文章内容不能为空'
      } else {
        editorStatus.isValid = true
        editorStatus.message = ''
      }
    }
    onMounted(() => {
      if (editorRef.value) {
        console.log('editorRef', editorRef.value.getMDEInstance())
      }
      if (isEditMode) {
        store.dispatch('fetchPost', route.query.id).then((rawData: PostProps) => {
          const currentPost = rawData
          if (currentPost.image) {
            uploadedData.value = currentPost.image
          }
          articleNameVal.value = currentPost.title
          contentVal.value = currentPost.content || ''
        })
      }
    })
    const handleFileUploaded = (rowData: ResponseType<ImageProps>) => {
      if (rowData.data._id) {
        imageId = rowData.data._id
      }
    }
    const onFormSummit = (result: boolean) => {
      checkEditor()
      if (result && editorStatus.isValid) {
        const { _id } = store.state.user
        const column = route.params.id as string
        if (column) {
          const newPosts: PostProps = {
            title: articleNameVal.value,
            content: contentVal.value,
            author: _id,
            column
          }
          if (imageId) {
            newPosts.image = imageId
          }
          const actionName = isEditMode ? 'updatePost' : 'createPost'
          const sendData = isEditMode
            ? {
                id: route.query.id,
                payload: newPosts
              }
            : newPosts
          store.dispatch(actionName, sendData).then(() => {
            createMessage(isEditMode ? '更新成功' : '创建成功', 'success', 2000)
            setTimeout(() => {
              router.push({ name: 'column', params: { id: column } })
            }, 2000)
          })
        }
      }
    }
    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, { type: ['image/jpeg', 'image/png'], size: 1 })
      const { passed, error } = result
      if (error === 'type') {
        createMessage('上传文件格式不正确!', 'error', 2000)
      }
      if (error === 'size') {
        createMessage('上传文件大小不能超过1MB!', 'error', 2000)
      }
      return passed
    }
    return {
      articleNameVal,
      contentVal,
      articleNameRules,
      contentRules,
      onFormSummit,
      uploadCheck,
      handleFileUploaded,
      uploadedData,
      isEditMode,
      editOptions,
      editorRef,
      checkEditor,
      editorStatus
    }
  }
})
</script>
<style>
.create-post-page .file-upload-container{
  height: 200px;
  cursor: pointer;
}
.create-post-page .file-upload-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vue-easymde-editor.is-invalid {
  border: 1px solid red;
}
</style>
