import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps({
      class: "file-upload-container",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.triggerUpload && _ctx.triggerUpload(...args)), ["prevent"]))
    }, _ctx.$attrs), [
      (_ctx.fileStatus === 'loading')
        ? _renderSlot(_ctx.$slots, "loading", { key: 0 }, () => [
            _cache[2] || (_cache[2] = _createElementVNode("button", {
              class: "btn btn-primary",
              disabled: ""
            }, "正在上传...", -1))
          ])
        : (_ctx.fileStatus === 'success')
          ? _renderSlot(_ctx.$slots, "uploaded", {
              key: 1,
              uploadedData: _ctx.uploadedData
            }, () => [
              _cache[3] || (_cache[3] = _createElementVNode("button", { class: "btn btn-primary" }, "上传成功", -1))
            ])
          : _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
              _cache[4] || (_cache[4] = _createElementVNode("button", { class: "btn btn-primary" }, "点击上传", -1))
            ])
    ], 16),
    _createElementVNode("input", {
      type: "file",
      class: "file-input d-none",
      ref: "fileInput",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
    }, null, 544)
  ]))
}