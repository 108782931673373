import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "container text-center d-flex flex-column align-items-center",
  style: {"width":"80%"}
}
const _hoisted_2 = {
  key: 0,
  class: "row justify-content-start h-25 border-bottom mb-3 p-3 w-100"
}
const _hoisted_3 = { class: "col-3 align-self-center justify-content-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-9 text-start" }
const _hoisted_6 = { class: "text-break" }
const _hoisted_7 = { class: "loadmore-btn text-center mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_list = _resolveComponent("post-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.columnDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.columnDetails.avatar && _ctx.columnDetails.avatar.url,
                alt: _ctx.columnDetails.title,
                class: "rounded-circle border border-primary w-100"
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.columnDetails.title), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.columnDetails?.description), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_post_list, { list: _ctx.list }, null, 8, ["list"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.isLastPage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-outline-primary mt-2 mb-2 mx-auto btn-block w-25",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMorePage && _ctx.loadMorePage(...args)))
          }, " 加载更多 "))
        : _createCommentVNode("", true)
    ])
  ], 64))
}