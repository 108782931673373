<template>
  <div class="user-profile-component">
    <div class="d-flex align-items-center">
      <img :src="fitUrl" :alt="user.nickName" class="rounded-circle img-thumbnail">
      <div class="detail ml-2 flex-1">
        <h6 class="d-block mb-0">{{user.nickName}}</h6>
        <div class="description-content" :title="user.description">{{user.description}}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { UserProps } from '@/store'
import { addColumnAvatar } from '@/utils/helper'
export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup (props) {
    const fitUrl = computed(() => {
      addColumnAvatar(props.user, 50, 50)
      const { avatar } = props.user
      return avatar && avatar.fitUrl
    })
    return {
      fitUrl
    }
  }
})
</script>

<style scoped>
img {
  width: 50px;
  max-width: 50px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.description-content {
  max-height: 4.5em; /* 基于行高和行数计算的最大高度 */
  overflow: auto;
  /* 隐藏滚动条 */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.enlarged-image-container {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
.enlarged-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}
</style>
