<template>
  <div class="m-auto w-50 mt-3">
    <validate-form @form-submit='onFormSummit'>
      <div class='mb-3'>
        <label for='inputPassword5' class='form-label'>邮箱地址</label>
        <validate-input
          :rules='emailRules'
          v-model='emailVal'
          type='text'
          placeholder='请输入邮箱地址'
        />
      </div>
      <div class='mb-3'>
        <label for='inputPassword5' class='form-label'>密码</label>
        <validate-input
          :rules='passwordRules'
          v-model='passwordVal'
          type='password'
          placeholder='请输入密码'
        />
        <!-- <div id='passwordHelpBlock' class='form-text'></div> -->
      </div>
      <template #submit>
        <span class='btn btn-primary'>提交</span>
      </template>
      <template #clear>
        <span class='btn btn-secondary'>清空</span>
      </template>
    </validate-form>
  </div>
</template>
<script lang='ts'>
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import ValidateInput, { RuleProps } from '@/components/ValidateInput.vue'
import ValidateForm from '@/components/ValidateForm.vue'
import { useStore } from '@/store'
import createMessage from '@/components/createMessage'

export default defineComponent({
  name: 'Login',
  components: {
    ValidateInput,
    ValidateForm
  },
  setup () {
    const store = useStore()
    const emailVal = ref('111@test.com')
    const router = useRouter()
    const emailRules: RuleProps = [
      { type: 'required', message: '邮箱地址不能为空' },
      { type: 'email', message: '邮箱地址格式不正确' },
      { type: 'textRange', message: '邮箱地址长度不正确', min: 4, max: 30 }
    ]
    const passwordVal = ref('111111')
    const passwordRules: RuleProps = [
      { type: 'required', message: '密码不能为空' },
      { type: 'textRange', message: '密码长度不正确', min: 4, max: 30 }
    ]
    const emailRef = reactive({
      value: '',
      error: false,
      message: ''
    })
    const onFormSummit = (reuslt: boolean) => {
      console.log('validate form submit', reuslt)
      if (reuslt) {
        const payload = {
          email: emailVal.value,
          password: passwordVal.value
        }
        store.dispatch('loginAndFetchUserInfo', payload).then(() => {
          createMessage('登录成功，2s后跳转首页', 'success', 2000)
          setTimeout(() => {
            router.push('/')
          }, 2000)
        }).catch(err => {
          console.log(err)
        })
      }
    }
    return {
      emailRef,
      emailRules,
      emailVal,
      passwordVal,
      passwordRules,
      onFormSummit
    }
  }
})
</script>
