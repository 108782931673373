import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-profile-component" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "detail ml-2 flex-1" }
const _hoisted_5 = { class: "d-block mb-0" }
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.fitUrl,
        alt: _ctx.user.nickName,
        class: "rounded-circle img-thumbnail"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.user.nickName), 1),
        _createElementVNode("div", {
          class: "description-content",
          title: _ctx.user.description
        }, _toDisplayString(_ctx.user.description), 9, _hoisted_6)
      ])
    ])
  ]))
}