import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-4"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-12 text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "border boder-primary mb-3 p-3 pb-0 vh-25 w-100",
      key: post._id
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: `/post/${post._id}`,
          class: "col-12 text-start text-decoration-none"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(post.title), 1)
          ]),
          _: 2
        }, 1032, ["to"]),
        (post.image && typeof post.image != 'string')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (post.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "w-100",
                    src: post.image && post.image.fitUrl,
                    alt: ""
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: _normalizeClass({ 'col-8': post.image, 'col-12': !post.image, 'text-start': true, 'text-wrap': true}),
          style: {"display":"block","height":"auto","max-height":"200px","word-wrap":"break-word"}
        }, _toDisplayString(post.excerpt), 3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(post.createdAt), 1)
      ])
    ]))
  }), 128))
}