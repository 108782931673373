<template>
  <div class="row">
    <div class="col-4 mt-3" v-for="column in columnList" :key="column._id">
      <div class="card h-100 shadow-sm">
        <div class="card-body text-center d-flex flex-column justify-content-start align-items-center">
          <img class="card-img-top  border border-light my-3" :src="column.avatar && column.avatar.fitUrl" :alt="column.title">
          <h5 class="card-title">{{ column.title }}</h5>
          <p class="card-text text-start flex-grow-1">{{ column.description }}</p>
          <router-link :to="`/column/${column._id}`" class="btn btn-outline-primary">进入专栏</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ColumnProps } from '../store'
import { addColumnAvatar } from '@/utils/helper'

export default defineComponent({
  name: 'ColumnList',
  props: {
    list: {
      type: Array as PropType<ColumnProps[]>,
      required: true
    }
  },
  setup (props) {
    const columnList = computed(() => {
      return props.list.map(column => {
        addColumnAvatar(column, 50, 50)
        return column
      })
    })
    return {
      columnList
    }
  }
})
</script>

<style scoped>
.card-body img {
  width: 50px;
  height: 50px;
}
.card-title {
  max-height: 50px; /* 设置最大高度 */
  overflow: hidden; /* 隐藏超出部分 */
  white-space: nowrap; /* 不换行 */
  animation: scroll 10s linear infinite;
}
.card-text {
  max-height: 4.5em; /* 基于行高和行数计算的最大高度 */
  overflow: auto;
  /* 隐藏滚动条 */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
