import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "navbar bg-primary justify-content-between mb-1 p-2 h-auto",
  "data-bs-theme": "dark"
}
const _hoisted_2 = {
  key: 0,
  class: "list-inline mb-0"
}
const _hoisted_3 = { class: "list-inline-item" }
const _hoisted_4 = { class: "list-inline-item" }
const _hoisted_5 = {
  key: 1,
  class: "list-inline mb-0"
}
const _hoisted_6 = { class: "list-inline-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_drop_down_item = _resolveComponent("drop-down-item")!
  const _component_drop_down = _resolveComponent("drop-down")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "navbar-brand",
      href: "#"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("乐子专栏")
      ])),
      _: 1
    }),
    (!_ctx.user.isLogin)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: "/login",
              class: "btn btn-outline-light my-2"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("登录")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/registry",
              class: "btn btn-outline-light my-2"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("注册")
              ])),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_drop_down, {
              title: `你好 ${_ctx.user.nickName}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_drop_down_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/create/${_ctx.columnId}`
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("新建文章")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_drop_down_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/column/${_ctx.columnId}`
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("我的专栏")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_drop_down_item, { disabled: true }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("a", { href: "#" }, "编辑资料", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_drop_down_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: "/",
                      onClick: _ctx.onClickLogout
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("退出登录")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]))
  ]))
}