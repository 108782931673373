import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "loading-content" }
const _hoisted_2 = {
  class: "spinner-border text-primary",
  role: "status"
}
const _hoisted_3 = { class: "visually-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "text-primary small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#back" }, [
    _createElementVNode("div", {
      class: "d-flex justify-content-center align-items-center h-100 w-100 loading-container",
      style: _normalizeStyle({'backgroundColor': _ctx.background})
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text || 'loading'), 1)
        ]),
        (_ctx.text)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ]))
}