import mitt from 'mitt'
import { onUnmounted } from 'vue'
import { ValidateFunc, ClearInputFunc } from '@/components/ValidateForm.vue'
type Events = {
    'form-item-created': ValidateFunc,
    'form-item-cleared': ClearInputFunc,
}
export const emitter = mitt<Events>()

function mittEvent (event: 'form-item-created' | 'form-item-cleared', callback: (func:ValidateFunc | ClearInputFunc) => void | boolean, funcArr?: (ValidateFunc | ClearInputFunc)[]) {
  emitter.on(event, callback)
  onUnmounted(() => {
    emitter.off(event, callback)
    if (funcArr) {
      funcArr = []
    }
  })
}

export default mittEvent
