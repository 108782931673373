<template>
  <div class="post-detail-page">
    <modal
      title="删除文章"
      :visble="modelIsVisble"
      @modal-on-close="hideModal"
      @modal-on-confirm="deletePost"
    >
      <p>确定要删除这篇文章吗？</p>
    </modal>
    <article class="w-75 mx-auto mb-5 pb-3" v-if="currentPost">
      <img :src="currentImageUrl" alt="currentPost.title" class="image rounded-lg img-fluid my-4" v-if="currentImageUrl" @click="showEnlargedImage">
      <div v-if="showEnlarged" class="enlarged-image-container" @click="hideEnlargedImage">
        <img :src="currentImageUrl || ''" class="enlarged-image">
      </div>
      <h2 class="mb-4">{{currentPost.title}}</h2>
      <div class="user-profile-component border-top border-bottom py-3 mb-3 align-items-center row g-0">
        <div class="col-12">
          <user-profile :user="currentPost.author" v-if="typeof currentPost.author === 'object'"></user-profile>
        </div>
        <span class="text-muted col text-right font-italic">发表于：{{currentPost.createdAt}}</span>
      </div>
      <button type="button" class="btn mb-4" :class="enableMarkDown ? 'btn-success' : 'btn-warning'" @click="toggleMarkDown">{{ enableMarkDown ? 'Markdown On' : 'Markdown Off' }}</button>
      <div v-html="currentHTML" ></div>
      <div v-if="showEditButton" class="btn-group mt-5">
        <router-link
          type="button"
          class="btn btn-success"
          :to="{ name: 'create', query: { id: currentPost._id }}"
        >
          编辑
        </router-link>
        <button type="button" class="btn btn-danger" @click.prevent="showModal">删除</button>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore, PostProps, ImageProps, UserProps } from '@/store'
import Modal from '@/components/Modal.vue'
import { marked } from 'marked'
import UserProfile from '@/components/UserProfile.vue'
import createMessage from '@/components/createMessage'
export default defineComponent({
  name: 'PostDetail',
  components: {
    UserProfile,
    Modal
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const modelIsVisble = ref(false)
    const enableMarkDown = ref(true)
    const currentId = route.params.id
    onMounted(() => {
      store.dispatch('fetchPost', currentId)
    })
    const currentPost = computed<PostProps>(() => store.getters.getCurrentPost(currentId))
    const currentHTML = computed(() => {
      if (enableMarkDown.value) {
        if (currentPost.value && currentPost.value.content) {
          return marked.parse(currentPost.value.content)
        } else {
          return ''
        }
      } else {
        return currentPost.value && currentPost.value.content ? currentPost.value.content : ''
      }
    })
    const currentImageUrl = computed(() => {
      if (currentPost.value && currentPost.value.image) {
        const { image } = currentPost.value
        return (image as ImageProps).url + '?x-oss-process=image/resize,w_850'
      } else {
        return null
      }
    })
    const showEnlarged = ref(false)
    const showEnlargedImage = () => {
      showEnlarged.value = true
    }
    const hideEnlargedImage = () => {
      showEnlarged.value = false
    }
    const showEditButton = computed(() => {
      const { isLogin, _id } = store.state.user
      if (currentPost.value && currentPost.value.author && isLogin) {
        const postAuthor = currentPost.value.author as UserProps
        return postAuthor._id === _id
      } else {
        return false
      }
    })
    const showModal = () => {
      modelIsVisble.value = true
    }
    const hideModal = () => {
      modelIsVisble.value = false
    }
    const deletePost = () => {
      store.dispatch('deletePost', currentId).then((res) => {
        createMessage('删除成功,2秒后跳转到专栏页', 'success', 2000)
        router.push({ name: 'column', params: { id: res.column } })
      })
      modelIsVisble.value = false
    }
    const toggleMarkDown = () => {
      enableMarkDown.value = !enableMarkDown.value
      console.log('enableMarkDown', enableMarkDown.value)
    }
    return {
      currentPost,
      currentImageUrl,
      currentHTML,
      showEnlarged,
      showEnlargedImage,
      hideEnlargedImage,
      showEditButton,
      modelIsVisble,
      showModal,
      hideModal,
      deletePost,
      toggleMarkDown,
      enableMarkDown
    }
  }
})
</script>

<style scoped>
.image{
  width: 100%;
  max-height: 200px;
  object-fit: none;
  cursor: pointer;
}
.enlarged-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
.enlarged-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}
</style>
