<template>
    <div class = validate-input-container pb-3>
        <input v-if = 'tag === "input"'
            class="form-control"
            :class = "{ 'is-invalid': inputRef.error }"
            @blur="validateInput"
            v-model="inputRef.val"
            v-bind="$attrs"
        />
        <textarea v-else-if = 'tag === "textarea"'
            class="form-control"
            :class = "{ 'is-invalid': inputRef.error }"
            @blur="validateInput"
            v-model="inputRef.val"
            v-bind="$attrs"
        >
        </textarea>
        <span v-if="inputRef.error" class="invalid-feedback">{{ inputRef.message }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, onMounted, computed } from 'vue'
import { emitter } from '@/utils/emitter'
interface RuleProp {
  type: 'required' | 'email' | 'password' | 'textRange' | 'custom'
  message: string
  validator?: () => boolean
  min?: number
  max?: number
}
export type RuleProps = RuleProp[]
export type TagType = 'input' | 'select' | 'checkbox' | 'radio' | 'textarea'
const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
export default defineComponent({
  name: 'ValidateInput',
  props: {
    rules: Array as PropType<RuleProps>,
    modelValue: String,
    tag: {
      type: String as PropType<TagType>,
      default: 'input'
    }
  },
  inheritAttrs: false,
  setup (props, context) {
    const inputRef = reactive({
      val: computed({
        get: () => props.modelValue || '',
        set: val => {
          context.emit('update:modelValue', val)
        }
      }),
      error: false,
      message: ''
    })
    const validateInput = () => {
      if (props.rules) {
        const allPassed = props.rules.every(rule => {
          let passed = true
          inputRef.message = rule.message
          switch (rule.type) {
            case 'required':
              passed = (inputRef.val.trim() !== '')
              break
            case 'email':
              passed = (emailReg.test(inputRef.val))
              break
            case 'textRange':
              passed = (inputRef.val.length >= (rule.min ?? 0) && inputRef.val.length <= (rule.max ?? Infinity))
              rule.min && inputRef.val.length < rule.min && (inputRef.message = `输入内容不能少于${rule.min}个字符`)
              rule.max && inputRef.val.length > rule.max && (inputRef.message = `输入内容不能超过${rule.max}个字符`)
              break
            case 'custom':
              passed = rule.validator ? rule.validator() : true
              break
            default:
              break
          }
          return passed
        })
        inputRef.error = !allPassed
        return allPassed
      }
      return true
    }
    const clearInput = () => {
      inputRef.val = ''
      inputRef.error = false
      inputRef.message = ''
      context.emit('update:modelValue', '')
    }
    onMounted(() => {
      emitter.emit('form-item-created', validateInput)
      emitter.emit('form-item-cleared', clearInput)
    })
    return {
      inputRef,
      validateInput
    }
  }
})
</script>
