<template>
  <form class="validate-form-container">
    <slot name="default"></slot>
    <div class="d-flex justify-content-center p-2">
      <div class="summit-area d-inline-block ms-3" @click.prevent="submitForm">
          <slot name="submit">
              <button type="submit" class="btn btn-primary">提交</button>
          </slot>
      </div>
      <div class="summit-area d-inline-block ms-3 me-3" @click.prevent="clearForm">
          <slot name="clear">
          </slot>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import mittEvent from '@/utils/emitter'
export type ValidateFunc = () => void
export type ClearInputFunc = () => void
export default defineComponent({
  name: 'ValidateForm',
  emits: ['form-submit'],
  setup (props, context) {
    const validateFuncArr: ValidateFunc[] = []
    const clearInputFuncArr: ClearInputFunc[] = []
    const submitForm = () => {
      const result = validateFuncArr.map(func => func()).every(item => item)
      context.emit('form-submit', result)
    }
    const clearForm = () => {
      clearInputFuncArr.map(func => func())
    }
    const callbackCreate = (func: ValidateFunc) => {
      validateFuncArr.push(func)
    }
    const callbackClear = (func: ClearInputFunc) => {
      clearInputFuncArr.push(func)
    }
    const emitEventCreated = mittEvent('form-item-created', callbackCreate, validateFuncArr)
    const emitEventClear = mittEvent('form-item-cleared', callbackClear, clearInputFuncArr)
    return {
      submitForm,
      clearForm,
      emitEventCreated,
      emitEventClear
    }
  }
})
</script>
