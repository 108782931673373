import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/callout.svg'


const _hoisted_1 = { class: "home-page" }
const _hoisted_2 = { class: "py-5 text-center container" }
const _hoisted_3 = { class: "row py-lg-5" }
const _hoisted_4 = { class: "col-lg-6 col-md-8 mx-auto" }
const _hoisted_5 = { class: "loadmore-btn text-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_column_list = _resolveComponent("column-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            alt: "callout",
            class: "w-50"
          }, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "font-weight-light" }, "随心写作，自由表达", -1)),
          _createElementVNode("p", null, [
            _createVNode(_component_router_link, {
              to: `/create/${_ctx.columnId}`,
              class: "btn btn-primary my-2"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("开始写文章")
              ])),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "font-weight-bold text-center" }, "发现精彩", -1)),
    _createVNode(_component_column_list, { list: _ctx.list }, null, 8, ["list"]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.isLastPage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-outline-primary mt-2 mb-2 mx-auto btn-block w-25",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMorePage && _ctx.loadMorePage(...args)))
          }, " 加载更多 "))
        : _createCommentVNode("", true)
    ])
  ]))
}