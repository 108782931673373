import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-auto w-50 mt-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validate_input = _resolveComponent("validate-input")!
  const _component_validate_form = _resolveComponent("validate-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("h5", { class: "my-4 text-center" }, "注册者也账户", -1)),
    _createVNode(_component_validate_form, { onFormSubmit: _ctx.onFormSummit }, {
      submit: _withCtx(() => _cache[8] || (_cache[8] = [
        _createElementVNode("span", { class: "btn btn-primary" }, "注册新用户", -1)
      ])),
      clear: _withCtx(() => _cache[9] || (_cache[9] = [])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("label", {
            for: "inputEmail",
            class: "form-label"
          }, "邮箱地址", -1)),
          _createVNode(_component_validate_input, {
            id: "inputEmail",
            rules: _ctx.emailRules,
            modelValue: _ctx.formData.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
            type: "text",
            placeholder: "请输入邮箱地址",
            autocomplete: "off"
          }, null, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "inputNickName",
            class: "form-label"
          }, "昵称", -1)),
          _createVNode(_component_validate_input, {
            id: "inputNickName",
            rules: _ctx.textRules,
            modelValue: _ctx.formData.nickName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.nickName) = $event)),
            type: "text",
            placeholder: "请输入昵称",
            autocomplete: "off"
          }, null, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "inputPassword1",
            class: "form-label"
          }, "密码", -1)),
          _createVNode(_component_validate_input, {
            id: "inputPassword1",
            rules: _ctx.passwordRules,
            modelValue: _ctx.formData.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.password) = $event)),
            type: "password",
            placeholder: "请输入密码",
            autocomplete: "new-password"
          }, null, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "inputPassword2",
            class: "form-label"
          }, "重复密码", -1)),
          _createVNode(_component_validate_input, {
            id: "inputPassword2",
            rules: _ctx.confirmPasswordRules,
            modelValue: _ctx.formData.confirmPassword,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.confirmPassword) = $event)),
            type: "password",
            placeholder: "请确认输入密码",
            autocomplete: "new-password"
          }, null, 8, ["rules", "modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["onFormSubmit"])
  ]))
}