import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "post-detail-page" }
const _hoisted_2 = {
  key: 0,
  class: "w-75 mx-auto mb-5 pb-3"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "user-profile-component border-top border-bottom py-3 mb-3 align-items-center row g-0" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "text-muted col text-right font-italic" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 2,
  class: "btn-group mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_user_profile = _resolveComponent("user-profile")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal, {
      title: "删除文章",
      visble: _ctx.modelIsVisble,
      onModalOnClose: _ctx.hideModal,
      onModalOnConfirm: _ctx.deletePost
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("p", null, "确定要删除这篇文章吗？", -1)
      ])),
      _: 1
    }, 8, ["visble", "onModalOnClose", "onModalOnConfirm"]),
    (_ctx.currentPost)
      ? (_openBlock(), _createElementBlock("article", _hoisted_2, [
          (_ctx.currentImageUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.currentImageUrl,
                alt: "currentPost.title",
                class: "image rounded-lg img-fluid my-4",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showEnlargedImage && _ctx.showEnlargedImage(...args)))
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.showEnlarged)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "enlarged-image-container",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideEnlargedImage && _ctx.hideEnlargedImage(...args)))
              }, [
                _createElementVNode("img", {
                  src: _ctx.currentImageUrl || '',
                  class: "enlarged-image"
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.currentPost.title), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (typeof _ctx.currentPost.author === 'object')
                ? (_openBlock(), _createBlock(_component_user_profile, {
                    key: 0,
                    user: _ctx.currentPost.author
                  }, null, 8, ["user"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("span", _hoisted_8, "发表于：" + _toDisplayString(_ctx.currentPost.createdAt), 1)
          ]),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["btn mb-4", _ctx.enableMarkDown ? 'btn-success' : 'btn-warning']),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleMarkDown && _ctx.toggleMarkDown(...args)))
          }, _toDisplayString(_ctx.enableMarkDown ? 'Markdown On' : 'Markdown Off'), 3),
          _createElementVNode("div", { innerHTML: _ctx.currentHTML }, null, 8, _hoisted_9),
          (_ctx.showEditButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_router_link, {
                  type: "button",
                  class: "btn btn-success",
                  to: { name: 'create', query: { id: _ctx.currentPost._id }}
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" 编辑 ")
                  ])),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger",
                  onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)), ["prevent"]))
                }, "删除")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}