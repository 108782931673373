<template>
    <teleport to="#message">
      <div class="alert message-info fixed-top w-50 mx-auto d-flex justify-content-between mt-2"
        :class="classObject" v-if="isVisible">
        <span>{{message}}</span>
        <button type="button" class="btn-close" aria-label="Close"></button>
      </div>
    </teleport>
  </template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import useDOMCreate from '@/hooks/useDOMCreate'
export type MessageType = 'success' | 'error' | 'default'

export default defineComponent({
  name: 'Message',
  props: {
    message: String,
    type: {
      type: String as PropType<MessageType>,
      default: 'default'
    }
  },
  emits: ['close-message'],
  setup (props) {
    useDOMCreate('message')
    const isVisible = ref(true)
    const classObject = computed(() => {
      return {
        'alert-success': props.type === 'success',
        'alert-danger': props.type === 'error',
        'alert-info': props.type === 'default'
      }
    })
    return {
      isVisible,
      classObject
    }
  }
})
</script>

<style scoped>
.alert-success {
  background-color: #aaeaaa;
  color: black;
}
</style>
