import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "validate-form-container" }
const _hoisted_2 = { class: "d-flex justify-content-center p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "summit-area d-inline-block ms-3",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _renderSlot(_ctx.$slots, "submit", {}, () => [
          _cache[2] || (_cache[2] = _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary"
          }, "提交", -1))
        ])
      ]),
      _createElementVNode("div", {
        class: "summit-area d-inline-block ms-3 me-3",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearForm && _ctx.clearForm(...args)), ["prevent"]))
      }, [
        _renderSlot(_ctx.$slots, "clear")
      ])
    ])
  ]))
}