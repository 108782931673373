<template>
    <div class="m-auto w-50 mt-3">
      <h5 class="my-4 text-center">注册者也账户</h5>
      <validate-form @form-submit='onFormSummit'>
        <div class='mb-3'>
          <label for='inputEmail' class='form-label'>邮箱地址</label>
          <validate-input
            id="inputEmail"
            :rules='emailRules'
            v-model='formData.email'
            type='text'
            placeholder='请输入邮箱地址'
            autocomplete='off'
          />
        </div>
        <div class='mb-3'>
          <label for='inputNickName' class='form-label'>昵称</label>
          <validate-input
            id='inputNickName'
            :rules='textRules'
            v-model='formData.nickName'
            type='text'
            placeholder='请输入昵称'
            autocomplete='off'
          />
          <!-- <div id='passwordHelpBlock' class='form-text'></div> -->
        </div>
        <div class='mb-3'>
          <label for='inputPassword1' class='form-label'>密码</label>
          <validate-input
            id='inputPassword1'
            :rules='passwordRules'
            v-model='formData.password'
            type='password'
            placeholder='请输入密码'
            autocomplete="new-password"
          />
          <!-- <div id='passwordHelpBlock' class='form-text'></div> -->
        </div>
        <div class='mb-3'>
          <label for='inputPassword2' class='form-label'>重复密码</label>
          <validate-input
            id='inputPassword2'
            :rules='confirmPasswordRules'
            v-model='formData.confirmPassword'
            type='password'
            placeholder='请确认输入密码'
            autocomplete="new-password"
          />
          <!-- <div id='passwordHelpBlock' class='form-text'></div> -->
        </div>
      <template #submit>
        <span class='btn btn-primary'>注册新用户</span>
      </template>
      <template #clear>
      </template>
      </validate-form>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import ValidateForm from '@/components/ValidateForm.vue'
import ValidateInput, { RuleProps } from '@/components/ValidateInput.vue'
import request from '@/utils/request'
import createMessage from '@/components/createMessage'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Registry',
  components: {
    ValidateForm,
    ValidateInput
  },
  setup () {
    const formData = reactive({
      email: '',
      nickName: '',
      password: '',
      confirmPassword: ''
    })
    const emailRules: RuleProps = [
      { type: 'required', message: '邮箱地址不能为空' },
      { type: 'email', message: '请输入正确的邮箱地址' }
    ]
    const textRules: RuleProps = [
      { type: 'required', message: '昵称不能为空' },
      { type: 'textRange', min: 2, max: 16, message: '请输入2-16个字符' }
    ]
    const passwordRules: RuleProps = [
      { type: 'required', message: '密码不能为空' },
      { type: 'textRange', min: 4, max: 30, message: '请输入4-30个字符' }
    ]
    const confirmPasswordRules: RuleProps = [
      { type: 'required', message: '密码不能为空' },
      {
        type: 'custom',
        validator: () => {
          return formData.password === formData.confirmPassword
        },
        message: '两次输入的密码不一致'
      }
    ]
    const router = useRouter()
    const onFormSummit = (result: boolean) => {
      if (result) {
        const payload = {
          email: formData.email,
          nickName: formData.nickName,
          password: formData.password
        }
        request.post('/users', payload).then(
          () => {
            createMessage('注册成功.跳转到登录页面2s后', 'success', 2000)
            setTimeout(() => {
              router.push({ name: 'login' })
            }, 2000)
          }
        )
      }
    }
    return {
      formData,
      emailRules,
      textRules,
      passwordRules,
      confirmPasswordRules,
      onFormSummit
    }
  }
})
</script>
