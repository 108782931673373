<template>
<div class="border boder-primary mb-3 p-3 pb-0 vh-25 w-100" v-for="post in posts" :key="post._id">
    <div class="row">
        <router-link :to="`/post/${post._id}`" class="col-12 text-start text-decoration-none">{{ post.title }}</router-link>
        <div v-if = "post.image && typeof post.image != 'string'" class="col-4" >
          <img class="w-100" :src="post.image && post.image.fitUrl" alt="" v-if="post.image">
        </div>
        <p :class="{ 'col-8': post.image, 'col-12': !post.image, 'text-start': true, 'text-wrap': true}"
          style="display: block; height: auto; max-height: 200px; word-wrap:break-word"
        >{{ post.excerpt }}</p>
        <span class="col-12 text-start">{{ post.createdAt }}</span>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
// ImageProps作为post.image的断言
import { PostProps, ImageProps } from '@/store'
import { generateFitUrl } from '@/utils/helper'

export default defineComponent({
  name: 'PostList',
  props: {
    list: {
      type: Array as PropType<PostProps[]>,
      required: true
    }
  },
  setup (props) {
    const posts = computed(() => {
      return props.list.map(post => {
        if (post.image && typeof post.image as ImageProps) {
          generateFitUrl(post.image as ImageProps, 200, 200)
        }
        return post
      })
    })
    return {
      posts
    }
  }
})
</script>

<style lang="scss">

</style>
